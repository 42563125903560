import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import calender from "../../../../../resources/images/calendar.png";
import thumb from "../../../../../resources/images/thumb.png";
import upcomingAppointments from "../../../../../resources/images/upcomingappointments.png";
import FamilyRecord from "./FamilyRecord";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { getProfile } from "../../patientProfileSlice";
import { useParams } from "react-router";
// Health History Modal
function HealthHistory({ data }) {
  // States
  const { loading } = useSelector((state) => state.getPatientProfileReducer);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  return (
    <>
      <button onClick={() => setShow(true)} className="modalmainbutton">
        View
      </button>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Health History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {data.patientHealthHistory?.map((item) => (
              <div key={item.Id}>
                {item.subSectionName ? (
                  <div className="border rounded my-3 p-2">
                    <h4>{item.subSectionName}</h4>
                    {item.subQuestions.map((subItem) => (
                      <div
                        key={subItem.Id}
                        className="d-flex align-items-center justify-content-between px-2 py-1"
                        style={{ fontSize: "1rem", fontWeight: "400" }}
                      >
                        <p style={{ marginBottom: "0px" }}>{subItem.text}</p>
                        <div className="d-flex gap-4 align-items-center">
                          <span className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              checked={subItem.isChecked === true}
                              readOnly
                            />
                            <label>Yes</label>
                          </span>
                          <span className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              checked={subItem.isChecked === false}
                              readOnly
                            />
                            <label>No</label>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    className="d-flex align-items-center justify-content-between border rounded my-3 p-2"
                    style={{ fontSize: "1rem", fontWeight: "400" }}
                  >
                    <p style={{ marginBottom: "0px" }}>{item.text}</p>
                    <div
                      className="d-flex gap-4"
                      style={{ paddingRight: "8px" }}
                    >
                      <span className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          checked={item.isChecked === true}
                          readOnly
                        />
                        <label>Yes</label>
                      </span>
                      <span className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          checked={item.isChecked === false}
                          readOnly
                        />
                        <label>No</label>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={() => dispatch(getProfile(params.id, false))}
          >
            {loading ? (
              <div
                className="spinner-border text-white spinner-border-sm"
                role="status"
              ></div>
            ) : (
              <>
                <RestartAltIcon />
                Refresh
              </>
            )}
          </button>
        </Modal.Body>
      </Modal>
      <style jsx="true">
        {`
          .modalmainbutton {
            background: #009b94;
            color: #fff;
            border: none;
            border-radius: 4px;
            padding: 4px 16px;
          }
          input[type="checkbox"] {
            margin-right: 5px;
            font-size: 1rem;
            font-weight: 400;
            height: 18px;
            width: 18px;
          }
        `}
      </style>
    </>
  );
}
// this is overview of patient
function Overview() {
  const dispatch = useDispatch();
  const { value, loading } = useSelector(
    (state) => state.getPatientProfileReducer
  );
  useEffect(() => {
    dispatch({ type: "MedicalHistoryFilter/resetState" });
  });
  return (
    <>
      {/* {loading && <div className="loading"></div>} */}
      <div className="container-fluid my-3 ">
        <div className="mainover d-flex gap-3">
          <div className="leftover">
            <div className="col">
              <table className="bg-white rounded profile-card-width mb-3 w-100">
                <thead></thead>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>
                      {value.Fname?.charAt(0).toUpperCase() +
                        value.Fname?.slice(1)}
                    </td>
                  </tr>
                  <tr>
                    <th>Phone</th>
                    <td>{value.phoneNo}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{value.email}</td>
                  </tr>
                  <tr>
                    <th>D.O.B</th>
                    <td>{moment(value.profiles.self.dob).format("ll")}</td>
                  </tr>
                  <tr>
                    <th>Gender</th>
                    <td>{value.gender}</td>
                  </tr>
                  <tr>
                    <th>City</th>
                    <td>
                      {value.profiles.self.location?.charAt(0).toUpperCase() +
                        value.profiles.self.location?.slice(1)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col">
              <table className="bg-white rounded profile-card-width mb-3 w-100">
                <thead></thead>
                <tbody>
                  <tr>
                    <th>Current Balance</th>
                    <td>{value.wallet}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col">
              <table className="bg-white rounded profile-card-width mb-3 w-100">
                <thead></thead>
                <tbody>
                  <tr>
                    <th>Patient Health History</th>
                    <td>
                      {value?.profiles?.self?.healthHistoryData ? (
                        <HealthHistory
                          data={value?.profiles?.self?.healthHistoryData}
                        />
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="rightover mx-2">
            <div
              className="row "
              style={{ gap: " 5px", marginRight: "10px", flexWrap: "unset" }}
            >
              <div className="col-4 bg-white  rounded p-3 ">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-column">
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      Patient Rating
                    </span>
                    <span
                      className="mt-1"
                      style={{ fontSize: "14px", fontWeight: "400" }}
                    >
                      {value.yearsOfExperience} Years
                    </span>
                  </div>
                  <div>
                    <img src={thumb} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-4 bg-white rounded p-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-column">
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      Upcoming Appointment
                    </span>
                    <span
                      className="mt-1"
                      style={{ fontSize: "14px", fontWeight: "400" }}
                    >
                      {value.upcomingAppointmentCount >= 1
                        ? value.upcomingAppointmentCount
                        : "0"}{" "}
                      Appointments
                    </span>
                  </div>
                  <div>
                    <img src={upcomingAppointments} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-4 bg-white rounded p-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-column">
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      Total Appointments
                    </span>
                    <span
                      className="mt-1"
                      style={{ fontSize: "14px", fontWeight: "400" }}
                    >
                      {value.totalAppointmentCount >= 1
                        ? value.totalAppointmentCount
                        : "0"}{" "}
                      Appointments
                    </span>
                  </div>
                  <div>
                    <img src={calender} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col" style={{ marginRight: "10px" }}>
              <FamilyRecord />
            </div>
          </div>
        </div>
      </div>
      <style jsx="true">{`
        .leftover {
          width: 25%;
        }
        .rightover {
          width: 75%;
        }
        table {
          font-size: 14px;
        }
        th {
          font-weight: 500;
          padding: 15px;
        }
        td {
          text-align: right;
          padding: 15px;
        }
        tr {
          border-bottom: 1px solid #dadada;
        }
        .cancelled {
          background: #ffe0e0;
          color: Red;
        }
        .booked {
          background: #d9f7e7a6;
          color: Green;
        }
        .missed {
          background: #ffe0e0;
          color: Red;
        }
        .reserved {
          background: #fff6e9;
          color: Orange;
        }
        .reschedule {
          background: #00b8af1a;
          color: #009b94;
        }
        .InProgress {
          background: #e0f3fb;
          color: #00568a;
        }
        .completed {
          background: #d9f7e7a6;
          color: #1a9452;
        }
        .loading {
          position: fixed;
          z-index: 999;
          height: 2em;
          width: 2em;
          overflow: show;
          margin: auto;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }

        /* Transparent Overlay */
        .loading:before {
          content: "";
          display: block;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: radial-gradient(
            rgba(20, 20, 20, 0.8),
            rgba(0, 0, 0, 0.8)
          );

          background: -webkit-radial-gradient(
            rgba(20, 20, 20, 0.8),
            rgba(0, 0, 0, 0.8)
          );
        }

        /* :not(:required) hides these rules from IE9 and below */
        .loading:not(:required) {
          /* hide "loading..." text */
          font: 0/0 a;
          color: transparent;
          text-shadow: none;
          background-color: transparent;
          border: 0;
        }

        .loading:not(:required):after {
          content: "";
          display: block;
          font-size: 10px;
          width: 1em;
          height: 1em;
          margin-top: -0.5em;
          -webkit-animation: spinner 150ms infinite linear;
          -moz-animation: spinner 150ms infinite linear;
          -ms-animation: spinner 150ms infinite linear;
          -o-animation: spinner 150ms infinite linear;
          animation: spinner 150ms infinite linear;
          border-radius: 0.5em;
          -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
            rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
            rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
            rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
            rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
            rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
            rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
            rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
          box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
            rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
            rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
            rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
            rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
            rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
            rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
            rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
        }

        /* Animation */

        @-webkit-keyframes spinner {
          0% {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @-moz-keyframes spinner {
          0% {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @-o-keyframes spinner {
          0% {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @keyframes spinner {
          0% {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @media screen and (max-width: 1550px) {
          .leftover {
            width: 50%;
          }
          .rightover {
            width: 100%;
          }
        }
        @media screen and (max-width: 1050px) {
          .mainover {
            flex-wrap: wrap;
          }
        }
        @media screen and (max-width: 500px) {
          .leftover {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
}

export default Overview;
